import React from "react";
import ChartistGraph from "react-chartist";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  Image,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Label } from "reactstrap";
import { getAllTotalAreaManager } from "stores";
import { getAllTotalPengajuan } from "stores";
import ReactDatetime from "react-datetime";
import { getTotalPencairan } from "stores/dashboardUtama/function";
import { getGrafikPencairanCabang } from "stores";
import moment from "moment";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLegend,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import { getTotalTargetUser } from "stores/Pengajuan/function";
import { getGrafikPencairanUser } from "stores";
import { getAllTotalSupervisor } from "stores";
import { getAllTotalMarketing } from "stores";
import { getAllTotalCabang } from "stores";
import { getExportPengajuan } from "stores";
import "assets/img/logo-sulutgo.png";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getAllWilayah } from "stores";

function DashboardUtama() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const dashboard = useSelector((state) => state.pengajuanReducer);
  // const cardTotalPencairan = useSelector((state) => state.pengajuanReducer);
  const cardTotalPengajuan = useSelector(
    (state) => state.dashboardUtamaReducer
  );

  const tampilTotalPengajuan = useSelector(
    (state) => state.dashboardUtamaReducer.totalAllPengajuan
  );

  const [modalMenunggu, setModalMenunggu] = React.useState(false);
  const [modalRevisi, setModalRevisi] = React.useState(false);
  const [modalDitolak, setModalDitolak] = React.useState(false);
  const [modalDisetujui, setModalDisetujui] = React.useState(false);
  const [modalDiproses, setModalDiproses] = React.useState(false);

  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 10;
  const totalTarget = hasilPresentase > 100;
  const [totalNominalPencairan, setTotalNominalPencairan] = React.useState(0);
  const [listWilayah, setListWilayah] = React.useState([]);
  const [idWilayah, setIdWilayah] = React.useState({
    label: "All",
    value: "",
  });

  const [hasilPresentase, setHasilPresentase] = React.useState(0);
  const [totalNominalTarget, setTotalNominalTarget] = React.useState(0);
  const [dataGrafikCabang, setDataGrafikCabang] = React.useState([]);
  const [dataGrafikUser, setDataGrafikUser] = React.useState([]);
  const [totalSpv, setTotalSpv] = React.useState([]);
  const [totalCbg, setTotalCbg] = React.useState([]);
  const [totalAM, setTotalAM] = React.useState([]);
  const [totalMarketing, setTotalMarketing] = React.useState([]);
  const [dataAllGrafikCabang, setDataAllGrafikCabang] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [tglAwal, setTglAwal] = React.useState("");
  const [tglAkhir, setTglAkhir] = React.useState("");
  const [modalExport, setModalExport] = React.useState(false);
  const [dariTgl, setDariTgl] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
      0,
      0,
      0,
      0
    )
  );
  const [modalDetail, setModalDetail] = React.useState(false);
  const [smpTgl, setSmpTgl] = React.useState(new Date());
  const pencairanData = dataGrafikCabang.map((item) => item.totalPencairan);
  const targetData = dataGrafikCabang.map((item) => item.totalTarget);
  const [tahun, setTahun] = React.useState(new Date().getFullYear());
  let yearNow = new Date().getFullYear();
  const [listYear, setListYear] = React.useState([]);
  const bulanData = dataGrafikUser.map((item) => item.bulan);

  const pencairanDataUser = dataGrafikUser.map(
    (item) => item.totalNominalPencairan
  );

  const targetDataUser = dataGrafikUser.map((item) => item.totalNominalTarget);
  const abjadCabang = dataGrafikCabang.map((_, index) =>
    String.fromCharCode(65 + index)
  );

  const formatRupiah = (angka) => {
    const numberFormat = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    });
    return numberFormat.format(angka);
  };

  const options =
    auth.role === "super admin"
      ? [{ value: "", label: "All" }, ...listWilayah]
      : listWilayah;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const closeModal = () => {
    setCurrentPage(1);
    setModalDetail(false);
    setModalMenunggu(false);
    setModalRevisi(false);
    setModalDiproses(false);
    setModalDisetujui(false);
    setModalDitolak(false);
  };

  const chartData = {
    labels: abjadCabang,
    series: [pencairanData, targetData],
  };

  const chartDataUser = {
    labels: bulanData,
    series: [pencairanDataUser, targetDataUser],
  };

  const handleExportPengajuan = () => {
    setStatus("");
    setTglAwal("");
    setTglAkhir("");
  };

  const submitExport = (e) => {
    e.preventDefault();
    // console.log(tglAwal, tglAkhir, status.value, "ASLKASDASDSAD");
    // if (status === "") {
    //   Swal.fire({
    //     title: "Status Harus Diisi",
    //     icon: "warning",
    //   });
    //   return;
    // }
    getExportPengajuan({
      tglAwal: tglAwal,
      tglAkhir: tglAkhir,
      statusPengajuan: status.value,
    });
  };

  React.useEffect(() => {
    // setIsVisible(true);s
    getListYear();
  }, []);

  React.useEffect(() => {
    getAllTotalPengajuan(dispatch, { idWilayah: idWilayah.value });
  }, [idWilayah]);

  const getListYear = () => {
    let tmp = [];
    for (let i = 5; i >= 0; i--) {
      let years = yearNow--;
      tmp.push({
        value: years,
        label: years,
      });
    }
    setListYear(tmp);
  };

  React.useEffect(() => {
    getTotalPencairan(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
      idWilayah: idWilayah.value,
    }).then((response) => {
      // console.log(response, "PPPP");
      const totalPencairan = response.data.totalNominal;
      setTotalNominalPencairan(totalPencairan);
    });
  }, [dariTgl, smpTgl, idWilayah]);

  React.useEffect(() => {
    getTotalTargetUser(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
      idWilayah: idWilayah.value,
    }).then((response) => {
      const totalTarget = response.data.totalTarget;
      setTotalNominalTarget(totalTarget);
    });
  }, [dariTgl, smpTgl, idWilayah]);

  React.useEffect(() => {
    getGrafikPencairanCabang(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
      idWilayah: idWilayah.value,
    }).then((response) => {
      const grafikCabang = response.data;
      let tmp = [];
      grafikCabang.map((val, index) => {
        if (index < 15) {
          tmp.push(val);
        }
      });
      setDataGrafikCabang(tmp);
      setDataAllGrafikCabang(grafikCabang);
    });
  }, [dariTgl, smpTgl, idWilayah]);

  React.useEffect(() => {
    getGrafikPencairanUser(dispatch, {
      tahun: tahun,
      idWilayah: idWilayah.value,
    }).then((response) => {
      const grafikUser = response.data;
      // console.log(grafikUser, "GRAFIK");
      setDataGrafikUser(grafikUser);
    });
  }, [tahun, idWilayah]);

  React.useEffect(() => {
    if (totalNominalPencairan !== 0 && totalNominalTarget !== 0) {
      const presentase = Math.round(
        (totalNominalPencairan / totalNominalTarget) * 100
      );
      setHasilPresentase(presentase);
    } else {
      setHasilPresentase(0);
    }
  }, [totalNominalPencairan, totalNominalTarget]);

  React.useEffect(() => {
    getAllTotalSupervisor(dispatch, { idWilayah: idWilayah.value }).then(
      (response) => {
        const dataSupervisor = response.data;
        setTotalSpv(dataSupervisor);
      }
    );
  }, [idWilayah]);

  React.useEffect(() => {
    getAllTotalAreaManager(dispatch, { idWilayah: idWilayah.value }).then(
      (response) => {
        const dataAreaManager = response.data;
        setTotalAM(dataAreaManager);
      }
    );
  }, [idWilayah]);

  React.useEffect(() => {
    getAllTotalMarketing(dispatch, { idWilayah: idWilayah.value }).then(
      (response) => {
        const dataMarketing = response.data;
        setTotalMarketing(dataMarketing);
      }
    );
  }, [idWilayah]);

  React.useEffect(() => {
    getAllTotalCabang(dispatch, { idWilayah: idWilayah.value }).then(
      (response) => {
        // console.log(response, "CABANG");
        const dataCabang = response.data;
        setTotalCbg(dataCabang);
      }
    );
  }, [idWilayah]);

  const customStyles = {
    rows: {
      style: {
        height: 70, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colCabangPencairan = [
    {
      name: "No",
      width: "70px",
      center: true,
      selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
    },
    {
      name: "Nama Cabang",
      minWidth: "150px",
      center: true,
      selector: (row) => row.nama,
    },
    {
      name: "Total Pencairan",
      minWidth: "200px",
      center: true,
      selector: (row) => formatRupiah(row.totalPencairan),
    },
    {
      name: "Total Target",
      minWidth: "200px",
      center: true,
      selector: (row) => formatRupiah(row.totalTarget),
    },
    {
      name: "Total Nasabah",
      minWidth: "120px",
      center: true,
      omit: auth.role === "super admin" || auth.role === "admin" ? false : true,
      selector: (row) => row.totalNasabah,
    },
  ];

  React.useEffect(() => {
    let tmp = [{ value: "", label: "All" }];
    cardTotalPengajuan.allWilayah &&
      cardTotalPengajuan.allWilayah.map((val) => {
        // console.log(val, "VAL WILAYAH");
        tmp.push({
          value: val._id,
          label: val.namaWilayah,
        });
      });
    setListWilayah(tmp);
    let Obj = cardTotalPengajuan.allWilayah.find(
      (x) => x.namaWilayah === "Gorontalo"
    );
    // setIdWilayah({ value: Obj._id, label: Obj.namaWilayah });
    // console.log(Obj, ">>>>>>>>>>>>>>>>");
  }, [cardTotalPengajuan.allWilayah]);

  React.useEffect(() => {
    getAllWilayah(dispatch).then((response) => {
      // const dataCabang = response.data;
      // setTotalCbg(dataCabang);
    });
  }, []);

  React.useEffect(() => {
    if (auth.role === "client") {
      const interval = setInterval(() => {
        window.location.reload();
        refresh();
      }, 300000);

      return () => clearInterval(interval);
    }
  }, [auth.role]);
  // console.log(idWilayah, "?????????????????????");
  return (
    <>
      {/* <Modal
        size="md"
        show={modalMenunggu}
        onHide={closeModal}
        // onHide={() => setModalDetail(!modalDetail)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h3"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Detail
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body style={{ marginTop: 20 }}>
              <div className="row">
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    Gorontalo
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.menungguGoron}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    Sulut
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.menungguSulut}
                    </Label>
                  </li>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="md"
        show={modalRevisi}
        onHide={closeModal}
        // onHide={() => setModalDetail(!modalDetail)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h3"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Detail
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body style={{ marginTop: 20 }}>
              <div className="row">
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    Gorontalo
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.revisiGoron}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    Sulut
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.revisiSulut}
                    </Label>
                  </li>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="md"
        show={modalDiproses}
        onHide={closeModal}
        // onHide={() => setModalDetail(!modalDetail)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h3"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Detail
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body style={{ marginTop: 20 }}>
              <div className="row">
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    Gorontalo
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.diprosesGoron}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    Sulut
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.diprosesSulut}
                    </Label>
                  </li>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="md"
        show={modalDisetujui}
        onHide={closeModal}
        // onHide={() => setModalDetail(!modalDetail)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h3"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Detail
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body style={{ marginTop: 20 }}>
              <div className="row">
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    Gorontalo
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.disetujuiGoron}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    Sulut
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.disetujuiSulut}
                    </Label>
                  </li>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="md"
        show={modalDitolak}
        onHide={closeModal}
        // onHide={() => setModalDetail(!modalDetail)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h3"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Detail
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body style={{ marginTop: 20 }}>
              <div className="row">
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    Gorontalo
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.ditolakGoron}
                    </Label>
                  </li>
                </div>
                <div className="col-6">
                  <Label
                    style={{
                      fontSize: 16,
                      marginTop: 15,
                      fontWeight: "bold",
                      color: "#828286",
                      textTransform: "uppercase",
                    }}
                  >
                    Sulut
                  </Label>
                  <li>
                    <Label
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "black",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {cardTotalPengajuan.totalPengajuan &&
                        cardTotalPengajuan.totalPengajuan.data.ditolakGoron}
                    </Label>
                  </li>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Modal> */}

      <Modal
        size="lg"
        show={modalDetail}
        onHide={closeModal}
        // onHide={() => setModalDetail(!modalDetail)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Detail Data Cabang Pencairan
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body style={{ marginTop: 20 }}>
              <DataTable
                columns={colCabangPencairan}
                data={dataAllGrafikCabang}
                // .filter(
                //   (row) =>
                //     row.nama.toLowerCase().includes(searchText.toLowerCase()) ||
                //     row.nopeg.toLowerCase().includes(searchText.toLowerCase())
                // )}
                customStyles={customStyles}
                pagination
                paginationPerPage={itemsPerPage}
                onChangePage={handlePageChange}
              />
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="lg"
        show={modalExport}
        onHide={() => {
          setModalExport(false);
          handleExportPengajuan();
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card>
                  <Card.Header>
                    <Card.Header>
                      <Card.Title
                        as="h4"
                        style={{ fontWeight: "bold", textTransform: "none" }}
                      >
                        Export Pengajuan
                      </Card.Title>
                    </Card.Header>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Tanggal Awal
                          </label>
                          <Form.Control
                            onChange={(e) => {
                              setTglAwal(e.target.value);
                            }}
                            type="date"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Tanggal Akhir
                          </label>
                          <Form.Control
                            onChange={(e) => {
                              setTglAkhir(e.target.value);
                            }}
                            type="date"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Status
                          </label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="singleStatus"
                            onChange={(value) => setStatus(value)}
                            options={[
                              {
                                value: "",
                                label: "Pilih Status",
                                isDisabled: true,
                              },
                              {
                                value: "Disetujui",
                                label: "Disetujui",
                              },
                            ]}
                            placeholder="Pilih Status"
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      variant="info"
                      onClick={submitExport}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Card>
        <Container fluid>
          <Row>
            <Col md={"4"} style={{ marginTop: 2 }}>
              <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
                Pilih Wilayah
              </h4>
              <Select
                style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                className="react-select primary"
                classNamePrefix="react-select"
                name="singleSelect"
                defaultValue={{
                  label: "All",
                  value: "",
                }}
                onChange={(value) => {
                  setIdWilayah(value);
                }}
                options={listWilayah}
              />
            </Col>
            <Col md={"4"}>
              <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
                Tanggal Awal
              </h4>
              <Form.Group
                style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
              >
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Date Picker Here",
                    readOnly: true,
                    style: {
                      cursor: "default",
                      color: "black",
                      backgroundColor: "white",
                    },
                  }}
                  onChange={(e) => {
                    e.toDate().setHours(0, 0, 0, 0),
                      setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                  }}
                  value={dariTgl}
                  initialValue={dariTgl}
                  timeFormat={false}
                ></ReactDatetime>
              </Form.Group>
            </Col>
            <Col md={"4"}>
              <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
                Tanggal Akhir
              </h4>
              <Form.Group
                style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
              >
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Date Picker Here",
                    readOnly: true,
                    style: {
                      cursor: "default",
                      color: "black",
                      backgroundColor: "white",
                    },
                  }}
                  onChange={(e) => {
                    e.toDate().setHours(23, 59, 0, 0),
                      setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                  }}
                  value={smpTgl}
                  initialValue={smpTgl}
                  timeFormat={false}
                ></ReactDatetime>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Card
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: 10,
                  marginTop: 15,
                }}
              >
                <Card.Header
                  className="d-none d-md-inline"
                  style={{
                    backgroundColor: "#944E63",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    border: 0,
                  }}
                >
                  <Card.Title as="h3">
                    <Row>
                      <Col
                        md="8"
                        className="p-3"
                        style={{
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>bpd sulutgo</b>
                      </Col>
                      <Col md="4" className="p-2 d-flex justify-content-end">
                        <Image
                          src={
                            "https://ptbap.net/assets/images/logo/invert-bap.png"
                          }
                          width="auto"
                          height="50px"
                        />
                      </Col>
                    </Row>
                  </Card.Title>
                </Card.Header>
                {/* <Card.Body
                style={{
                  backgroundColor: "white",
                }}
              > */}
                <Row>
                  <Col
                    lg="5"
                    className="d-none d-lg-flex align-items-center justify-content-center"
                  >
                    <Image
                      src={require("assets/img/logo-sulutgo.png").default}
                      width="auto"
                      height="150px"
                    />
                  </Col>
                  <Col lg="7" xs="12">
                    <div className="numbers d-flex justify-content-around">
                      <div className="d-flex flex-column align-items-center">
                        <p className="card-category">
                          <h3>
                            <b
                              style={{
                                color: "black",
                                fontWeight: "bold",
                                fontSize: 27,
                              }}
                            >
                              Pencapaian
                            </b>
                          </h3>
                        </p>
                        <Card.Title
                          as="h2"
                          style={{
                            color:
                              hasilPresentase > 100 ? "#26DB53" : "#FF4D4F",
                            fontFamily: "inherit",
                            fontWeight: "bold",
                          }}
                        >
                          <b>{hasilPresentase} %</b>
                        </Card.Title>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <p className="card-category">
                          <h3>
                            <b
                              style={{
                                color: "black",
                                fontWeight: "bold",
                                fontSize: 27,
                              }}
                            >
                              Target
                            </b>
                          </h3>
                        </p>
                        <Card.Title
                          as="h2"
                          style={{
                            color: "#26DB53",
                            fontFamily: "inherit",
                            fontWeight: "bold",
                          }}
                        >
                          <b>100%</b>
                        </Card.Title>
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* </Card.Body> */}
              </Card>
            </Col>

            <Col lg="6" sm="6">
              <Row className="mb-3">
                {/* <Col md="6">
                  <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
                    Tanggal Awal
                  </h4>
                  <Form.Group
                    style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                  >
                    <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: "Date Picker Here",
                        readOnly: true,
                        style: {
                          cursor: "default",
                          color: "black",
                          backgroundColor: "white",
                        },
                      }}
                      onChange={(e) => {
                        e.toDate().setHours(0, 0, 0, 0),
                          setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                      }}
                      value={dariTgl}
                      initialValue={dariTgl}
                      timeFormat={false}
                    ></ReactDatetime>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
                    Tanggal Akhir
                  </h4>
                  <Form.Group
                    style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                  >
                    <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: "Date Picker Here",
                        readOnly: true,
                        style: {
                          cursor: "default",
                          color: "black",
                          backgroundColor: "white",
                        },
                      }}
                      onChange={(e) => {
                        e.toDate().setHours(23, 59, 0, 0),
                          setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                      }}
                      value={smpTgl}
                      initialValue={smpTgl}
                      timeFormat={false}
                    ></ReactDatetime>
                  </Form.Group>
                </Col> */}
              </Row>

              <Row>
                <Col lg="6" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#944E63",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Total Pencairan</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {isNaN(totalNominalPencairan)
                        ? formatRupiah(0)
                        : formatRupiah(totalNominalPencairan)}
                    </Card.Body>
                    {/* <Row style={{ fontSize: 12 }}>
                    <Col
                      className="p-2 d-flex justify-content-center"
                      style={{ fontWeight: "bold", marginTop: 10 }}
                    >
                      {formatRupiah(dashboard.totalPencairan.totalNominalSulut)}
                      <Row
                        style={{
                          position: "absolute",
                          bottom: 30,
                        }}
                      >
                        Sulut
                      </Row>
                    </Col>

                    <Col
                      className="p-2 d-flex justify-content-center"
                      style={{ fontWeight: "bold", marginTop: 10 }}
                    >
                      {formatRupiah(
                        dashboard.totalPencairan.nominalPencairanGorontalo
                      )}
                      <Row
                        style={{
                          position: "absolute",
                          bottom: 30,
                        }}
                      >
                        Gorontalo
                      </Row>
                    </Col>
                  </Row> */}
                  </Card>
                </Col>
                <Col lg="6" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#944E63",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Total Target</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {formatRupiah(totalNominalTarget)}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col lg="6" sm="6">
              <h4
                className="title mt-2"
                style={{ fontWeight: "bold", marginBottom: 33 }}
              >
                Informasi
              </h4>
              <Row>
                <Col lg="6" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#B47B84",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Area Manager</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {totalAM.totalAm} Karyawan
                    </Card.Body>
                    {/* <Row style={{ fontSize: 12 }}>
                    <Col
                      className="p-2 d-flex justify-content-center"
                      style={{ fontWeight: "bold", marginTop: 10 }}
                    >
                      {totalAM.totalAreaManagerGorontalo} Karyawan
                      <Row
                        style={{
                          position: "absolute",
                          bottom: 30,
                        }}
                      >
                        Gorontalo
                      </Row>
                    </Col>

                    <Col
                      className="p-2 d-flex justify-content-center"
                      style={{ fontWeight: "bold", marginTop: 10 }}
                    >
                      {totalAM.totalAreaManagerSulut} Karyawan
                      <Row
                        style={{
                          position: "absolute",
                          bottom: 30,
                        }}
                      >
                        Sulut
                      </Row>
                    </Col>
                  </Row> */}
                  </Card>
                </Col>
                <Col lg="6" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#B47B84",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Supervisor</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {totalSpv.totalSpv} Karyawan
                    </Card.Body>
                    {/* <Row style={{ fontSize: 12 }}>
                    <Col
                      className="p-2 d-flex justify-content-center"
                      style={{ fontWeight: "bold", marginTop: 10 }}
                    >
                      {totalSpv.totalSpvGorontalo} Karyawan
                      <Row
                        style={{
                          position: "absolute",
                          bottom: 30,
                        }}
                      >
                        Gorontalo
                      </Row>
                    </Col>

                    <Col
                      className="p-2 d-flex justify-content-center"
                      style={{ fontWeight: "bold", marginTop: 10 }}
                    >
                      {totalSpv.totalSpvSulut} Karyawan
                      <Row
                        style={{
                          position: "absolute",
                          bottom: 30,
                        }}
                      >
                        Sulut
                      </Row>
                    </Col>
                  </Row> */}
                  </Card>
                </Col>
                {/* {auth.role === "admin" || auth.role === "super admin" ? (
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#B47B84",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>SM Sudah Login</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {totalMarketing.totalLogin} Karyawan
                    </Card.Body>
                  </Card>
                </Col>
              ) : null} */}

                <Col lg="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#B47B84",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Cabang</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {totalCbg.totalCabang} Cabang
                    </Card.Body>
                    {/* <Row style={{ fontSize: 12 }}>
                    <Col
                      className="p-2 d-flex justify-content-center"
                      style={{ fontWeight: "bold", marginTop: 10 }}
                    >
                      {totalCbg.totalCabangSulut} Cabang
                      <Row
                        style={{
                          position: "absolute",
                          bottom: 30,
                        }}
                      >
                        Sulut
                      </Row>
                    </Col>

                    <Col
                      className="p-2 d-flex justify-content-center"
                      style={{ fontWeight: "bold", marginTop: 10 }}
                    >
                      {totalCbg.totalCabangGorontalo} Cabang
                      <Row
                        style={{
                          position: "absolute",
                          bottom: 30,
                        }}
                      >
                        Gorontalo
                      </Row>
                    </Col>
                  </Row> */}
                  </Card>
                </Col>

                <Col lg="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#B47B84",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Marketing</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {totalMarketing.totalSm} Karyawan
                    </Card.Body>
                    {/* <Row style={{ fontSize: 12 }}>
                    <Col
                      className="p-2 d-flex justify-content-center"
                      style={{ fontWeight: "bold", marginTop: 10 }}
                    >
                      {totalMarketing.totalSmGorontalo} Karyawan
                      <Row
                        style={{
                          position: "absolute",
                          bottom: 30,
                        }}
                      >
                        Gorontalo
                      </Row>
                    </Col>

                    <Col
                      className="p-2 d-flex justify-content-center"
                      style={{ fontWeight: "bold", marginTop: 10 }}
                    >
                      {totalMarketing.totalSmSulut} Karyawan
                      <Row
                        style={{
                          position: "absolute",
                          bottom: 30,
                        }}
                      >
                        Sulut
                      </Row>
                    </Col>
                  </Row> */}
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col lg="6" sm="6">
              <div className="status-container">
                <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
                  Status
                  {/* {auth.role === "super admin" ||
                  auth.role === "admin" ||
                  (auth.role === "client" && auth.jabatan === "korcab") ||
                  auth.nopeg === "rizalrompis" ||
                  auth.nopeg === "susansampangai" ? ( */}
                  <Button
                    className="btn-wd mr-1"
                    onClick={() => {
                      setModalExport(true);
                    }}
                    // variant="success"
                    style={{
                      marginLeft: 20,
                      backgroundColor: "#DEFAE5",
                      fontWeight: "bold",
                      color: "#78A083",
                      borderColor: "#4C787E",
                    }}
                  >
                    Export Pengajuan
                    <img
                      alt="..."
                      src={require("assets/img/excel.png").default}
                      style={{
                        width: "22px",
                        height: "25px",
                        marginLeft: 10,
                      }}
                    />
                  </Button>
                  {/* ) : null} */}
                </h4>
              </div>
              <Row>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#B47B84",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>menunggu</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {tampilTotalPengajuan.length === 0 ? (
                        <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                          <Skeleton height={"100%"} width={60} />
                        </SkeletonTheme>
                      ) : (
                        tampilTotalPengajuan.menunggu
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#B47B84",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Revisi</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {tampilTotalPengajuan.length === 0 ? (
                        <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                          <Skeleton height={"100%"} width={60} />
                        </SkeletonTheme>
                      ) : (
                        tampilTotalPengajuan.revisi
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#B47B84",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Diproses</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {tampilTotalPengajuan.length === 0 ? (
                        <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                          <Skeleton height={"100%"} width={60} />
                        </SkeletonTheme>
                      ) : (
                        tampilTotalPengajuan.proses
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#B47B84",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Disetujui</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {tampilTotalPengajuan.length === 0 ? (
                        <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                          <Skeleton height={"100%"} width={60} />
                        </SkeletonTheme>
                      ) : (
                        tampilTotalPengajuan.disetujui
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#B47B84",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Ditolak</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {tampilTotalPengajuan.length === 0 ? (
                        <SkeletonTheme color="#BDC7C9" highlightColor="#BDC7C9">
                          <Skeleton height={"100%"} width={60} />
                        </SkeletonTheme>
                      ) : (
                        tampilTotalPengajuan.ditolak
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <Card.Title
                    // as="h3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "black",
                      textTransform: "uppercase",
                    }}
                  >
                    <span
                      style={{ flex: "1", fontSize: 30, fontWeight: "bold" }}
                    >
                      Cabang
                    </span>
                    {/* <Col md={"3"}>
                    <label style={{ color: "black", fontSize: 14 }}>
                      Pilih Wilayah
                    </label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      defaultValue={{
                        label: "Gorontalo",
                        value: "6639d229b146b868cc8dc90f",
                      }}
                      // onChange={(selectedOption) =>
                      //   handleFilterChange(selectedOption)
                      // }
                      options={listWilayah}
                    />
                  </Col> */}
                  </Card.Title>
                  <hr />
                </Card.Header>
                <Row>
                  <Col md="12">
                    <div>
                      <div style={{ textAlign: "center" }}>
                        <div
                          style={{
                            display: "inline-block",
                            backgroundColor: "#7F669D",
                            width: 15,
                            height: 15,
                            marginRight: 5,
                          }}
                        ></div>
                        <label style={{ fontSize: 14 }}>Pencairan cabang</label>
                        <div
                          style={{
                            display: "inline-block",
                            backgroundColor: "#79AC78",
                            width: 15,
                            height: 15,
                            marginLeft: 20,
                            marginRight: 5,
                          }}
                        ></div>
                        <label style={{ fontSize: 14 }}>Target Cabang</label>
                      </div>
                      <VictoryChart
                        height={280}
                        width={850}
                        style={{ parent: { marginLeft: "1rem" } }}
                        domainPadding={{ x: 50 }}
                      >
                        <VictoryGroup
                          offset={13}
                          colorScale={["#7F669D", "#79AC78"]}
                        >
                          <VictoryBar
                            data={chartData.series[0].map((yValue, index) => {
                              const yInBillion = yValue / 1000000000;
                              const decimalDigits = yInBillion
                                .toString()
                                .split(".")[1];
                              // const dyValue = decimalDigits && decimalDigits.length > 2 ? -5 : -10;

                              return {
                                x: chartData.labels[index],
                                y: yInBillion,
                                label: `${yInBillion}`,
                                // dy: dyValue,
                              };
                            })}
                            barWidth={10}
                            labelComponent={
                              <VictoryTooltip
                                pointerLength={0}
                                flyoutStyle={{
                                  stroke: "none",
                                  fill: "#7F669D",
                                  padding: 5,
                                }}
                                dy={-2}
                                style={{
                                  fontSize: 10,
                                  fill: "white",
                                }}
                              />
                            }
                          />

                          <VictoryBar
                            data={chartData.series[1].map((yValue, index) => {
                              const yInBillion = yValue / 1000000000;
                              const decimalDigits = yInBillion
                                .toString()
                                .split(".")[1];
                              // const dyValue =
                              //   decimalDigits && decimalDigits.length > 2
                              //     ? -5
                              //     : -10;

                              return {
                                x: chartData.labels[index],
                                y: yInBillion,
                                label: `${yInBillion}`,
                                // dy: dyValue,
                              };
                            })}
                            barWidth={10}
                            labelComponent={
                              <VictoryTooltip
                                pointerLength={0}
                                flyoutStyle={{
                                  stroke: "none",
                                  fill: "#79AC78",
                                  padding: 5,
                                }}
                                dy={-2}
                                style={{
                                  fontSize: 10,
                                  fill: "white",
                                }}
                              />
                            }
                          />
                        </VictoryGroup>
                        <VictoryAxis
                          label="Nama Cabang"
                          tickLabelComponent={
                            <VictoryLabel style={{ fontSize: "10px" }} />
                          }
                          style={{
                            axisLabel: {
                              padding: 30,
                              fontSize: "8px",
                              fontStyle: "italic",
                              fontWeight: "bold",
                            },
                          }}
                        />
                        <VictoryAxis
                          dependentAxis
                          tickCount={6}
                          label="Nominal Pencairan dalam Miliar"
                          style={{
                            axisLabel: {
                              padding: 42.5,
                              fontSize: "9px",
                              fontStyle: "italic",
                              fontWeight: "bold",
                            },
                            tickLabels: {
                              fontSize: "10px",
                              // padding: 30,
                              // textAnchor: "start",
                            },
                          }}
                          domain={
                            chartData.series.some((data) =>
                              data.slice(0, 10).some((yValue) => yValue !== 0)
                            )
                              ? undefined
                              : { y: [0, 1] }
                          }
                        />
                      </VictoryChart>
                    </div>
                  </Col>
                  <Col md="12" style={{ marginTop: 30 }}>
                    <Card
                      style={{
                        border: 0,
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        borderRadius: 10,
                        marginRight: 30,
                        marginLeft: 30,
                        // backgroundColor: "#FFF59D",
                        color: "black",
                        padding: 20,
                      }}
                    >
                      <div style={{ marginBottom: 20 }}>
                        <Row>
                          <Col>
                            <h4
                              className="title mt-2"
                              style={{
                                fontWeight: "bold",
                                marginLeft: 20,
                                textAlign: "start",
                              }}
                            >
                              Keterangan Nama Cabang
                            </h4>
                          </Col>
                          <Col style={{ textAlign: "end" }}>
                            <Button
                              className="mr-1"
                              // variant="primary"
                              style={{
                                marginBottom: 10,
                                fontSize: 16,
                                backgroundColor: "#EC4129",
                                border: 0,
                                paddingLeft: 10,
                                paddingRight: 10,
                                fontWeight: "bold",
                              }}
                              onClick={() => setModalDetail(!modalDetail)}
                            >
                              Detail Semua Cabang
                            </Button>
                          </Col>
                        </Row>
                      </div>

                      <div
                        style={{
                          marginLeft: 20,
                          flexWrap: "wrap",
                          display: "flex",
                        }}
                      >
                        {dataGrafikCabang.map((cabang, index) => {
                          return (
                            <div
                              style={{
                                borderWidth: 2,
                                width: 300,
                                marginTop: 10,
                              }}
                            >
                              <Row key={index}>
                                <Col sm="1">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 14,
                                      color: "black",
                                      fontWeight: "bold",
                                      color: "black",
                                    }}
                                  >
                                    {String.fromCharCode(65 + index)}
                                  </label>
                                </Col>
                                <Col sm="o">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 14,
                                      color: "black",

                                      color: "black",
                                    }}
                                  >
                                    :
                                  </label>
                                </Col>
                                <Col sm="9">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 14,
                                      color: "black",

                                      color: "black",
                                    }}
                                  >
                                    {cabang.nama}
                                  </label>
                                </Col>
                              </Row>
                            </div>
                          );
                        })}

                        {/* <Row>
                        <Col md="6">
                          {dataGrafikCabang.map((cabang, index) =>
                            index < 5 ? (
                              <Row key={index}>
                                <Col sm="1">
                                 
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                      marginTop: 10,
                                      fontWeight: "bold",
                                      color: "black",
                                    }}
                                  >
                                    {String.fromCharCode(65 + index)}
                                  </label>
                                  
                                </Col>
                                <Col sm="o">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                      marginTop: 10,
                                      color: "black",
                                    }}
                                  >
                                    :
                                  </label>
                                </Col>
                                <Col sm="9">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                      marginTop: 10,
                                      color: "black",
                                    }}
                                  >
                                    {cabang.nama}
                                  </label>
                                </Col>
                              </Row>
                            ) : null
                          )}
                        </Col>

                        <Col md="6">
                          {dataGrafikCabang.map((cabang, index) =>
                            index >= 5 ? (
                              <Row key={index}>
                                <Col sm="1">
                                
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                      marginTop: 10,
                                      fontWeight: "bold",
                                      color: "black",
                                    }}
                                  >
                                    {String.fromCharCode(65 + index)}
                                  </label>
                        
                                </Col>
                                <Col sm="o">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                      marginTop: 10,
                                      color: "black",
                                    }}
                                  >
                                    :
                                  </label>
                                </Col>
                                <Col sm="9">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                      marginTop: 10,
                                      color: "black",
                                    }}
                                  >
                                    {cabang.nama}
                                  </label>
                                </Col>
                              </Row>
                            ) : null
                          )}
                        </Col>
                      </Row> */}
                      </div>
                      {/* <div
                      style={{
                        textAlign: "end",
                      }}
                    >
                      <Button
                        className="btn-wd mr-1"
                        variant="primary"
                        style={{ marginTop: 15, marginBottom: 10 }}
                        onClick={() => setModalDetail(!modalDetail)}
                      >
                        Tampilkan Detail Semua Cabang
                      </Button>
                    </div> */}
                    </Card>
                  </Col>
                </Row>

                {/* <ChartistGraph
                  data={chartData}
                  type="Bar"
                  options={{
                    seriesBarDistance: 10,
                    axisX: {
                      showGrid: false,
                    },
                    height: "245px",
                    labelInterpolationFnc: function (value, index) {
                      return index % 2 === 0 ? value : null; // Menampilkan setiap elemen genap
                    },
                  }}
                  // ...
                /> */}
                {/* </Card.Body> */}
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Card>
                <Card.Header>
                  <Card.Title
                    // as="h3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "black",
                      textTransform: "uppercase",
                    }}
                  >
                    <span
                      style={{ flex: "1", fontSize: 30, fontWeight: "bold" }}
                    >
                      {auth.nama}
                    </span>
                  </Card.Title>
                  <hr />
                </Card.Header>
                <Col md={"3"}>
                  <label style={{ color: "black", fontSize: 14 }}>
                    Pilih Tahun
                  </label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    defaultValue={{ label: yearNow, value: yearNow }}
                    onChange={(value) => {
                      setTahun(value.value);
                    }}
                    options={listYear}
                  />
                </Col>
                <Row>
                  <Col md="12">
                    <div>
                      <div style={{ textAlign: "center" }}>
                        <div
                          style={{
                            display: "inline-block",
                            backgroundColor: "#FFD966",
                            width: 15,
                            height: 15,
                            marginRight: 5,
                          }}
                        ></div>
                        <label style={{ fontSize: 14 }}>Pencairan User</label>
                        <div
                          style={{
                            display: "inline-block",
                            backgroundColor: "#046582",
                            width: 15,
                            height: 15,
                            marginLeft: 20,
                            marginRight: 5,
                          }}
                        ></div>
                        <label style={{ fontSize: 14 }}>Target User</label>
                      </div>
                      <VictoryChart
                        height={250}
                        width={850}
                        style={{ parent: { marginLeft: "1rem" } }}
                        domainPadding={{ x: 50 }}
                      >
                        <VictoryGroup
                          offset={12.5}
                          colorScale={["#FFD966", "#046582"]}
                        >
                          <VictoryBar
                            data={chartDataUser.series[0].map(
                              (yValue, index) => {
                                const yInBillion = yValue / 1000000000;
                                const decimalDigits = yInBillion
                                  .toString()
                                  .split(".")[1];
                                // const dyValue = decimalDigits && decimalDigits.length > 2 ? -5 : -10;

                                return {
                                  x: chartDataUser.labels[index],
                                  y: yInBillion,
                                  label: `${yInBillion}`,
                                  // dy: dyValue,
                                };
                              }
                            )}
                            barWidth={10}
                            labelComponent={
                              <VictoryTooltip
                                pointerLength={0}
                                flyoutStyle={{
                                  stroke: "none",
                                  fill: "#FFD966",
                                  padding: 5,
                                }}
                                dy={-2}
                                style={{
                                  fontSize: 10,
                                  fill: "white",
                                }}
                              />
                            }
                          />

                          <VictoryBar
                            data={chartDataUser.series[1].map(
                              (yValue, index) => {
                                const yInBillion = yValue / 1000000000;
                                const decimalDigits = yInBillion
                                  .toString()
                                  .split(".")[1];
                                // const dyValue = decimalDigits && decimalDigits.length > 2 ? -5 : -10;

                                return {
                                  x: chartDataUser.labels[index],
                                  y: yInBillion,
                                  label: `${yInBillion}`,
                                  // dy: dyValue,
                                };
                              }
                            )}
                            barWidth={10}
                            labelComponent={
                              <VictoryTooltip
                                dy={-2}
                                pointerLength={0}
                                flyoutStyle={{
                                  stroke: "none",
                                  fill: "#046582",
                                  padding: 5,
                                }}
                                style={{
                                  fontSize: 10,
                                  fill: "white",
                                }}
                              />
                            }
                          />
                        </VictoryGroup>
                        <VictoryAxis
                          tickLabelComponent={
                            <VictoryLabel style={{ fontSize: "10px" }} />
                          }
                          label="Bulan"
                          style={{
                            axisLabel: {
                              padding: 30,
                              fontSize: "8px",
                              fontStyle: "italic",
                              fontWeight: "bold",
                            },
                          }}
                        />
                        <VictoryAxis
                          dependentAxis
                          label="Nominal Pencairan dalam Miliar"
                          style={{
                            axisLabel: {
                              padding: 42.5,
                              fontSize: "8px",
                              fontStyle: "italic",
                              fontWeight: "bold",
                            },
                            tickLabels: {
                              fontSize: "10px",
                            },
                          }}
                          domain={
                            chartDataUser.series.some((data) =>
                              data.some((yValue) => yValue !== 0)
                            )
                              ? undefined
                              : { y: [0, 1] }
                          }
                        />
                      </VictoryChart>
                    </div>
                  </Col>
                </Row>

                {/* <ChartistGraph
                  data={chartData}
                  type="Bar"
                  options={{
                    seriesBarDistance: 10,
                    axisX: {
                      showGrid: false,
                    },
                    height: "245px",
                    labelInterpolationFnc: function (value, index) {
                      return index % 2 === 0 ? value : null; // Menampilkan setiap elemen genap
                    },
                  }}
                  // ...
                /> */}
                {/* </Card.Body> */}
              </Card>
            </Col>
          </Row>
        </Container>
      </Card>
    </>
  );
}

export default DashboardUtama;
