import { baseAxios } from "../index";

export async function tambahKorcab(data) {
  try {
    const response = await baseAxios.post("webKorcab/tambahKorcab", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    // console.log(response, "ENDPOINT");
    return response;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function getAllKorcab(dispatch, data, history) {
  try {
    const response = await baseAxios("webKorcab/getAllKorcab", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALL_KORCAB", data: response.data });
    console.log(response, " GETALL");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function penempatanKorcab(data) {
  try {
    const response = await baseAxios.post("webKorcab/penempatanKorcab", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllCalonNasabah(dispatch, data, history) {
  // console.log(data, "AAA");
  try {
    const response = await baseAxios.get(
      `webKorcab/getAllCalonNasabah?page=${data.page}&limit=${data.limit}&cari=${data.cari}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_CALON_NASABAH", data: response.data });
    // console.log(response, "getAllCalonNasabah");
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function getGroupKunjunganByCalonNasabah(dispatch, data, history) {
  // console.log(data, "AAA");
  try {
    const response = await baseAxios.get(
      `webKorcab/getGroupKunjunganByCalonNasabah?idCalonNasabah=${data.idCalonNasabah}&page=${data.page}&limit=${data.limit}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_GROUP_KUNJUNGAN_BYID", data: response.data });
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function getListKunjunganByGroup(dispatch, data, history) {
  // console.log(data, "AAA");
  try {
    const response = await baseAxios.get(
      `webKorcab/getListKunjunganByGroup?idGroup=${data.idGroupKunjungan}&page=${data.page}&limit=${data.limit}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_LIST_KUNJUNGAN_BYGROUP", data: response.data });
    // console.log(response, "getListKunjunganByGroup");
    return response.status;
  } catch (err) {
    return err.response;
  }
}

export async function updateApprovalKunjungan(data) {
  // console.log(data, "AAA");
  try {
    const response = await baseAxios.post(
      "webKorcab/updateApprovalKunjungan",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    // console.log(response, "ENDPOINT");
    return response;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function settingMenuKorcab(data) {
  try {
    const response = await baseAxios.post("webKorcab/settingMenuKorcab", data, {
      headers: { token: localStorage.getItem("token") },
    });
    // dispatch({ type: "SET_ALL_PROFILE", data: response.data });
    return response;
  } catch (err) {
    return err.response;
  }
}
